import React, { Component } from "react";
import GeneralWasteTopBanner from "../topbannerComponent/generalwastetopbanner";
import WhyChooseUs from "../bodyComponent/whychooseus";
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';

class Generalwaste extends Component {
  componentDidMount() {
    var data = {};
    data.event = "VirtualPageview";
    data.virtualPageURL = "/landing/generalwaste";
    data.virtualPageTitle = "Landing Page (General Waste)";
    // Enables the data layer if user has consented to enabling GTM, otherwise does not run it.
    // Data layer is used to enable GTM
    Cookies.get('statisticsCookiesConsent') == 'true' ? window.dataLayer.push(data) : console.log("")
  }

  render() {
    return (
      <div className="">
        <GeneralWasteTopBanner />
        <Helmet>
          <title>
          General Waste | SUEZ Instant Recycling and Waste Collection Quote
          </title>
          <meta
            name="description"
            content="Commercial General waste and recycling collection services for businesses. Competitive pricing and environmentally friendly. Apply for instant general waste collection quote online today."
          />
          <link rel="canonical" href="https://quotes.suez.co.uk/general-waste" />
          <meta
            name="keywords"
            content="general waste, business general waste, waste collectors, business waste, general waste collection, general waste bin, general waste collectors, trade waste collection, general waste collectors, waste collection quote, general waste company, general waste quote, suez general waste, suez recycling and recovery UK, Commercial general waste"
          />
        </Helmet>

        <div className="MainBodyTextContainer">
          <h1 className="center-text">General waste</h1>
          <div className="aligntextleft">
            <div className="">
              <div className="row">
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
                <div className="col-md-10 col-md-10 col-xs-10 col-sm-10">
                  <p className="center-text boldfont">
                    We know that not all businesses are the same, which is why
                    we take the time to understand your waste management needs
                    and goals, allowing us to develop a unique proposition
                    specifically for your business.
                  </p>
                  <p>
                    SUEZ recycling and recovery UK provides a cost-effective,
                    reliable and convenient general waste collection service
                    tailored to the individual needs of your business.
                  </p>
                  <p>
                    We specialise in extracting value from waste. It is the key
                    both to sustainability and to providing the most
                    cost-effective service possible.
                  </p>
                  <p>
                    General waste is a type of waste classified as non-hazardous
                    and can be defined as waste that does not pose an immediate
                    threat to the environment. This includes materials such as
                    non-recyclable plastics and packaging.
                  </p>
                  <p>
                    You can rely on SUEZ to handle your general waste in a
                    manner that is compliant with all environmental legislation.
                  </p>
                </div>
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
              </div>
            </div>
          </div>
        </div>

        <div className="WhyUsContainer">
          <h2 className="center-text">
            Why choose our general waste collection service?{" "}
          </h2>
          <div className="ContainerBulletPoints">
            <div>
              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Cost-effective services </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Reliable and hassle-free collections{" "}
                  </span>
                </div>
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Guaranteed compliance with regulations
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Range of user-friendly containers
                  </span>
                </div>
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Excellent customer service</span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Zero-waste-to landfill solutions</span>
                </div>
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">A free, no-obligation quote</span>
                </div>
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>
            </div>
          </div>

          {/* Commented this section so it does not display, uncomment if required. */}
          {/* <div className="WhatsPermittedContainer">
            <h2 className="center-text">What is allowed?</h2>
            <div className="ContainerBulletPoints">
              <div>
                <div className="row">
                  <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <span className="item">General non-hazardous waste</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <span className="item">Paper</span>
                  </div>
                  <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                </div>

                <div className="row">
                  <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <span className="item">Metal</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <span className="item">Cardboard</span>
                  </div>
                  <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                </div>

                <div className="row">
                  <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <span className="item">Plastic packaging</span>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                    <span className="item">Floor sweepings</span>
                  </div>
                  <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                </div>
              </div>
            </div>
            </div> */}

          <div className="ContainerSizesContainer ">
            <h2 className="center-text">Container sizes</h2>

            <div className="col-xs-2 col-sm-8 col-md-8 col-lg-8 OuterContainerSizesContainer">
              <div className="row">
                <div className="InnerCenterContainerSizesContainer"></div>
                <div className="InnerCenterContainerSizesContainer">
                  <div
                    className="card-img-generalwaste-240L img-fluid"
                    alt="360L"
                  />
                  <h5 className="card-title">240L</h5>
                </div>

                <div className="InnerCenterContainerSizesContainer">
                  <div
                    className="card-img-generalwaste-360L img-fluid"
                    alt="360L"
                  />
                  <h5 className="card-title">360L</h5>
                </div>

                <div className="InnerCenterContainerSizesContainer">
                  <div
                    className="card-img-generalwaste-660L img-fluid"
                    alt="660L"
                  />
                  <h5 className="card-title">660L</h5>
                </div>

                <div className="InnerCenterContainerSizesContainer">
                  <div
                    className="card-img-generalwaste-1100L img-fluid"
                    alt="1100L"
                  />
                  <h5 className="card-title">1100L</h5>
                </div>

                <div className="InnerCenterContainerSizesContainer">
                  <div
                    className="card-img-generalwaste-FEL6CY img-fluid"
                    alt="FEL 6CY"
                  />
                  <h5 className="card-title">FEL 6CY</h5>
                </div>

                <div className="InnerCenterContainerSizesContainer">
                  <div
                    className="card-img-generalwaste-FEL8CY img-fluid"
                    alt="FEL 8CY"
                  />
                  <h5 className="card-title">FEL 8CY</h5>
                </div>

                <div className="InnerCenterContainerSizesContainer">
                  <div
                    className="card-img-generalwaste-FEL10CY img-fluid"
                    alt="FEL 10CY"
                  />
                  <h5 className="card-title">FEL 10CY</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Generalwaste;

import React, { Component } from "react";
import WasteTopBanner from "./wastetopbanner";

class ContactUsTopBanner extends Component {
  render() {
    return (
      <div className="topBannerContainer">
        <div
          className="contactusbanner firstSegment"
          aria-label="SUEZ Contact Us"
          src="/contactusbanner.jpg"
          alt="SUEZ Contact Us"
        >
          <WasteTopBanner />
        </div>
      </div>
    );
  }
}

export default ContactUsTopBanner;

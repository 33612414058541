import React, { Component } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Sidebar from "../../components/headerComponent/sidebar";

class Header extends Component {
  constructor(props) {
    super(props);
  }

  handleHomePageRedirect = () => {
    this.props.history.push("/");
  };

  handleContactUsRedirect = () => {
    this.props.history.push("/contact-us");
  };

  makePhoneCall = () => {
    window.open("tel:+448000830504");
  };
  makePhoneCallExistingCustomer = () => {
    window.open("tel:+448000931103");
  };

  render() {
    return (
      <div className="topbar">
        <header>
          <div className="row">
            <div className="col-md-2 col-sm-12 logoContainer page-header">
              <div className="logo" onClick={this.handleHomePageRedirect} />

              <div className="headerGlyphBtnWrapper">
                {/* <div
                  className="callusGlyph gtm-callus"
                  onClick={this.makePhoneCall}
                /> */}

                <div class="dropdownCallUs">
                  <div class="dropbtnCallUs"  ></div>
                  <div class="dropdown-contentCallUs">
                    <a onClick={this.makePhoneCall}>
                      {" "}
                      Instant quote: 0800 083 0504
                    </a>
                    <a onClick={this.makePhoneCallExistingCustomer}>
                      {" "}
                      Existing customer: 0800 093 1103
                    </a>
                  </div>
                </div>

                <div
                  className="contactusGlyph"
                  onClick={this.handleContactUsRedirect}
                />
              </div>

              <Sidebar />
            </div>

            <div className="col headertext ">
              <Route
                exact
                path="/food-waste"
                render={function () {
                  return <h1>Food waste</h1>;
                }}
              />
              <Route
                exact
                path="/general-waste"
                render={function () {
                  return <h1>General waste</h1>;
                }}
              />
              <Route
                exact
                path="/glass-recycling"
                render={function () {
                  return <h1>Glass recycling</h1>;
                }}
              />
              <Route
                exact
                path="/mixed-recycling"
                render={function () {
                  return <h1>Mixed recycling</h1>;
                }}
              />
              <Route
                exact
                path="/contact-us"
                render={function () {
                  return <h1>Contact us</h1>;
                }}
              />
              <Route
                exact
                path="/"
                render={function () {
                  return <h1> Commercial waste and recycling collection </h1>;
                }}
              />
              <Route
                exact
                path="/index.html"
                render={function () {
                  return <h1> Commercial waste and recycling collection </h1>;
                }}
              />
              <Route
                exact
                path="/about-us"
                render={function () {
                  return <h1>About us</h1>;
                }}
              />
              <Route
                exact
                path="/faq"
                render={function () {
                  return <h1>Frequently asked questions</h1>;
                }}
              />
            </div>

            <div className="headerbtns">
              <div className="row nav-btns">
                {/* Old button and contact number */}
                {/* <button
                  className="call-us topbar-btns gtm-callus"
                  onClick={this.makePhoneCall}
                >
                  {" "}
                  <span> Call us on 0800 083 0504 </span>{" "}
                </button> */}

                <div className="contactNumberButtonAlignment">
                  <button
                    className="call-us topbar-btns gtm-callus"
                    onClick={this.makePhoneCall}
                  >
                    {" "}
                    <span> Instant quote: 0800 083 0504 </span>{" "}
                  </button>

                  <button
                    className="call-us topbar-btns gtm-callus"
                    onClick={this.makePhoneCallExistingCustomer}
                  >
                    {" "}
                    <span> Existing customer: 0800 093 1103 </span>{" "}
                  </button>
                </div>

                <button
                  className="contact-us topbar-btns"
                  onClick={this.handleContactUsRedirect}
                >
                  {" "}
                  <span> Contact us </span>{" "}
                </button>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

export default withRouter(Header);

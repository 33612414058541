import React, { Component } from "react";
import WasteTopBanner from "./wastetopbanner";

class FaqTopBanner extends Component {
  render() {
    return (
      <div className="topBannerContainer">
        <div
          className="faqbanner firstSegment"
          aria-label="SUEZ FAQ"
          aria-label="SUEZ Frequently Asked Questions"
          src="/faqbanner.jpg"
          alt="SUEZ FAQ"
          alt="SUEZ Frequently Asked Questions"
        >
          <WasteTopBanner />
        </div>
      </div>
    );
  }
}

export default FaqTopBanner;

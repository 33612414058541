import React, { Component } from "react";
import { Helmet } from "react-helmet";
import ContactUsTopBanner from "../topbannerComponent/contactustopbanner";
import PrivacyPolicypdf from "../../documents/SUEZ Privacy Policy.pdf";
import Cookies from "js-cookie";

class ContactUs extends Component {
  // Setting the states which might change if there is a validation or a change on text boxes or ratio buttons ----------------
  constructor(props) {
    super(props);

    this.sendContactUsRequest = this.sendContactUsRequest.bind(this);

    const originUrl = window.location.origin;

    this.state = {
      origiUrl: window.location.origin,
      //Set value for deeplink
      baseUrl: originUrl + "/link/submitRequest?",
      contactName: "",
      contactNameError: "",
      companyName: "",
      companyNameError: "",
      contactMethod: "",
      emailAddress: "",
      emailAddressError: "",
      phoneNumber: "",
      phoneNumberError: "",
      sitePostCode: "",
      message: "",
      selectedOption: "Email",
      confirmCheck: false,
      checkboxError: "",
      GDPRUrl: originUrl + "/link/policydocuments?GDPRDocument=",
      isSendButtonDisabled: undefined,
    };
    this.goBack = this.goBack.bind(this); // initialise goback
  }

  //--------------------------------------------------------------------------------------------------------------------------------------------

  //Setting values for GTM-----------------------------------------
  componentDidMount() {
    var data = {};
    data.event = "contactMethod";
    data.contactMethod = this.state.selectedOption;
    // Enables the data layer if user has consented to enabling GTM, otherwise does not run it.
    // Data layer is used to enable GTM
    Cookies.get("statisticsCookiesConsent") == "true"
      ? window.dataLayer.push(data)
      : console.log("");

    var data2 = {};
    data2.event = "VirtualPageview";
    data2.virtualPageURL = "/quote/contact-us";
    data2.virtualPageTitle = "Contact Us";
  }

  // Functions that are run when each textbox changes value (On change) -----------------------------------------------------

  handleOptionChange(e) {
    this.setState({
      contactMethod: e.target.value,
      selectedOption: e.target.value,
    });
  }

  goBack() {
    this.props.history.goBack();
    window.scrollTo(0, 0);
  }

  handleChange() {}

  handlecontactName(e) {
    this.setState({
      contactName: e.target.value,
    });
  }

  handlecompanyName(e) {
    this.setState({
      companyName: e.target.value,
    });
  }

  handlecontactMethod(e) {
    this.setState({
      contactMethod: e.target.value,
    });
  }

  handleemailAddress(e) {
    this.setState({
      emailAddress: e.target.value.trim(),
    });
  }

  handlephoneNumber(e) {
    this.setState({
      phoneNumber: e.target.value,
    });
  }

  handlesitePostCode(e) {
    this.setState({
      sitePostCode: e.target.value,
    });
  }

  handlemessage(e) {
    this.setState({
      message: e.target.value,
    });
  }

  handleConfirmChange(e) {
    this.setState({
      confirmCheck: e.target.value,
    });
  }

  makePhoneCall = () => {
    window.open("tel:+448000830504");
  };
  makePhoneCallExistingCustomer = () => {
    window.open("tel:+448000931103");
  };

  // Handling on change - end of functions -----------------------------------------------------

  //----

  /*   getprivacyTerms = (e) => {
    e.preventDefault();
    const url = this.state.GDPRUrl + "PRIVACYTERMS"
    window.location.href = url;
  } */

  getprivacyTerms = (e) => {
    e.preventDefault();
    window.open(PrivacyPolicypdf);
  };

  //----

  // When the submit button is clicked, this runs-------------------------------------------------------
  sendContactUsRequest(e) {
    e.preventDefault();

    this.setState({
      isSendButtonDisabled: "true",
    });

    //this.updateValidationValues();
    const err = this.validate();

    if (!err) {
      const {
        baseUrl,
        companyName,
        contactName,
        contactMethod,
        emailAddress,
        phoneNumber,
        sitePostCode,
        message,
      } = this.state;
      const url = `${baseUrl}CompanyName=${companyName}&Name=${contactName}&Email=${emailAddress}&ContactMethod=${contactMethod}&Postcode=${sitePostCode}&Message=${message}&TelephoneNumber=${phoneNumber}`;

      window.open(url, "_self");
    } else {
      this.setState({
        isSendButtonDisabled: undefined,
      });
    }
  }

  // End of submit button function----------------------------------------------------------------------

  //This is to validate the fields -------------------------------------------------------------
  validate = () => {
    let isError = false;
    const errors = {};
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.state.contactName.length < 1) {
      isError = true;
      errors.contactNameError = "Please provide a contact name.";
    } else errors.contactNameError = "";

    if (this.state.companyName.length < 1) {
      errors.companyNameError = "Please provide a company name.";
      isError = true;
    } else errors.companyNameError = "";

    if (this.state.selectedOption === "Email") {
      if (!re.test(String(this.state.emailAddress).toLowerCase())) {
        errors.emailAddressError = "Please provide a valid email address.";
        isError = true;
      } else errors.emailAddressError = "";
    }

    if (this.state.selectedOption === "Phone") {
      if (this.state.phoneNumber.length < 10) {
        errors.phoneNumberError = "Please provide a valid phone number.";
        isError = true;
      } else errors.phoneNumberError = "";
    }

    if (this.state.confirmCheck === false) {
      errors.checkboxError =
        "You have to accept our privacy policy before you can submit your request.";
      isError = true;
    } else errors.checkboxError = "";

    this.setState({
      ...this.state,
      ...errors,
    });

    return isError;
  };

  // This is the end of validation. It returns a boolean-----------------------------------------------------

  render() {
    return (
      <div className="">
        <ContactUsTopBanner />
        <div className="contactus row">
          <Helmet>
            <title>
              Contact Us │ SUEZ UK Commercial Recycling and Waste Collection
            </title>
            <meta
              name="description"
              content="Contact SUEZ on 0800 083 0504 or email our customer service team today for your free commercial and business waste or recycling collection instant quote."
            />
            <link rel="canonical" href="https://quotes.suez.co.uk/contact-us" />
            <meta
              name="keywords"
              content="SUEZ recycling collection contact, SUEZ waste collection contact, business waste collection contact, SUEZ recycling phone number, SUEZ waste collection Freephone, contact SUEZ recycling and recovery , SUEZ waste collection email, SUEZ Recycling contact us, SUEZ waste collection email address"
            />
          </Helmet>

          <div className="col-md-9 col-sm-12">
            <form onSubmit={this.sendContactUsRequest}>
              <div className="form-group">
                <div>
                  <label className="control-label mandatory">
                    Contact name
                  </label>
                  <div>
                    <input
                      className={
                        this.state.contactNameError
                          ? "form-control validError"
                          : "form-control"
                      }
                      type="text"
                      onChange={this.handlecontactName.bind(this)}
                      placeholder="Your contact name"
                    />
                  </div>
                  <div className="validationError">
                    {this.state.contactNameError}
                  </div>
                </div>

                <div>
                  <label className="control-label mandatory">
                    Company name
                  </label>
                  <div>
                    <input
                      className={
                        this.state.companyNameError
                          ? "form-control validError"
                          : "form-control"
                      }
                      type="text"
                      onChange={this.handlecompanyName.bind(this)}
                      placeholder="Your company name"
                    />
                  </div>
                  <div className="validationError">
                    {this.state.companyNameError}
                  </div>
                </div>

                <div className="radiobutton-block">
                  <label className="control-label mandatory">
                    Preferred contact method
                  </label>
                  <div className="form-group">
                    <label
                      className={
                        this.state.selectedOption === "Email"
                          ? "radio-inline checked"
                          : "radio-inline"
                      }
                    >
                      <input
                        type="radio"
                        value="Email"
                        checked={this.state.selectedOption === "Email"}
                        onChange={this.handleOptionChange.bind(this)}
                      />
                      <span className="contactustext">Email</span>
                    </label>

                    <label
                      className={
                        this.state.selectedOption === "Phone"
                          ? "radio-inline checked"
                          : "radio-inline"
                      }
                    >
                      <input
                        type="radio"
                        value="Phone"
                        checked={this.state.selectedOption === "Phone"}
                        onChange={this.handleOptionChange.bind(this)}
                      />
                      <span className="contactustext">Phone</span>
                    </label>
                  </div>
                </div>
                <div>
                  <label className="control-label">Email address</label>
                  <div>
                    <input
                      className={
                        this.state.emailAddressError
                          ? "form-control validError"
                          : "form-control"
                      }
                      type="text"
                      onChange={this.handleemailAddress.bind(this)}
                      placeholder="Your email address"
                    />
                  </div>
                  <div className="validationError">
                    {this.state.emailAddressError}
                  </div>
                </div>

                <div>
                  <label className="control-label">Phone number</label>
                  <div>
                    <input
                      className={
                        this.state.phoneNumberError
                          ? "form-control validError"
                          : "form-control"
                      }
                      type="text"
                      pattern="[0-9]*"
                      onChange={this.handlephoneNumber.bind(this)}
                      placeholder="Your phone number"
                    />
                    <div className="validationError">
                      {this.state.phoneNumberError}
                    </div>
                  </div>
                </div>

                <div>
                  <label className="control-label">Site postcode</label>
                  <div>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handlesitePostCode.bind(this)}
                      placeholder="Your site postcode"
                    />
                  </div>
                </div>

                <div>
                  <label className="control-label">Message</label>
                  <div>
                    <input
                      className="form-control textarea"
                      type="textarea"
                      maxLength="500"
                      onChange={this.handlemessage.bind(this)}
                      placeholder="Message"
                    />
                  </div>
                </div>

                <div className="confirm_checkbox">
                  <input
                    type="checkbox"
                    onChange={this.handleConfirmChange.bind(this)}
                  />
                  <span className="contactustext">
                    {" "}
                    Please check to accept our{" "}
                  </span>{" "}
                  <a className="embededLink" onClick={this.getprivacyTerms}>
                    {" "}
                    privacy policy
                  </a>
                </div>
                <div className="validationError">
                  {this.state.checkboxError}
                </div>
              </div>
              <input
                type="submit"
                name="contactbtn"
                className="submit-btn gtm-contactus"
                value={
                  this.state.isSendButtonDisabled
                    ? "Submitting..."
                    : "Submit request"
                }
                disabled={this.state.isSendButtonDisabled}
              />
            </form>
          </div>
          <div className="col-md-3 col-sm-12 contactUsImage-wrapper">
            <div className="contactUsImage"> </div>
            <h4 className="OperatingHoursHeader">
            If you would like an instant quote, please call us on: {" "}
              <a href="javascript:void(0)" onClick={this.makePhoneCall}>
                {" "}
                0800 083 0504
              </a>
            </h4>
            <table className="contactTable">
              <tbody>
                <tr>
                  <td>Monday</td>
                  <td>09:00am to 5:00pm</td>
                </tr>
                <tr>
                  <td>Tuesday</td>
                  <td>09:00am to 5:00pm</td>
                </tr>
                <tr>
                  <td>Wednesday</td>
                  <td>09:00am to 5:00pm</td>
                </tr>
                <tr>
                  <td>Thursday</td>
                  <td>09:00am to 5:00pm</td>
                </tr>
                <tr>
                  <td>Friday</td>
                  <td>09:00am to 5:00pm</td>
                </tr>
              </tbody>
            </table>
            <h4 className="OperatingHoursHeader">
              If you're an existing customer and have a service-related enquiry
              please call:
              <a href="javascript:void(0)" onClick={this.makePhoneCallExistingCustomer}>
                {" "}
                0800 093 1103
              </a>
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;

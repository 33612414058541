import React, { Component } from "react";
import WhyChooseUs from "../bodyComponent/whychooseus";
import MixedWasteTopBanner from "../topbannerComponent/mixedwastetopbanner";
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';

class MixedRecycling extends Component {
  componentDidMount() {
    var data = {};
    data.event = "VirtualPageview";
    data.virtualPageURL = "/landing/mixed-recycling";
    data.virtualPageTitle = "Landing Page (Mixed Recycling)";
    // Enables the data layer if user has consented to enabling GTM, otherwise does not run it.
    // Data layer is used to enable GTM
    Cookies.get('statisticsCookiesConsent') == 'true' ? window.dataLayer.push(data) : console.log("")
  }

  render() {
    return (
      <div>
        <MixedWasteTopBanner />
        <Helmet>
          <title>
          Mixed Recycling │ SUEZ UK Commercial Recycling and Waste Collection
          </title>
          <meta
            name="description"
            content="Commercial mixed recycling collection services for businesses. Competitive pricing and environmentally friendly. Apply for instant mixed recycling collection quote online today."
          />
          <link rel="canonical" href="https://quotes.suez.co.uk/mixed-recycling"/>
          <meta
            name="keywords"
            content="mixed recycling, business mixed recycling, mixed recycling collectors, business mixed recycling, mixed recycling collection, mixed recycling bin, mixed recycling quote, mixed recycling company, mixed recycling quote, suez mixed recycling, suez recycling and recovery, Commercial mixed recycling"
          />
        </Helmet>

        <div className="MainBodyTextContainer">
          <h1 className="center-text">Mixed recycling</h1>
          <div className="aligntextleft">
            <div className="">
              <div className="row">
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
                <div className="col-md-10 col-md-10 col-xs-10 col-sm-10">
                <p className="center-text boldfont">
                Mixed recycling collection service is a convenient and effective way to dispose of recyclable materials.
                  </p>
                  <p>
                  SUEZ recycling and recovery UK provides a cost-effective, reliable and convenient mixed recycling collection service tailored to the individual needs of your business.
                  </p>
                  <p>
                  The materials can be mixed in one container making it a simple and cost-effective solution allowing you to focus on running your business while having the peace of mind that you are reducing your carbon footprint and boosting your business's recycling statistics.
                  </p>
                  <p>
                  Once collected, your recycling is sent to one of our materials recycling facilities within the UK for sorting. Valuable secondary raw materials are separated and sent to reprocessors, where your recycled cans, cardboard, paper and plastics are turned into new materials.
                  </p>
                </div>
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
              </div>
            </div>
          </div>
        </div>

        <div className="WhyUsContainer">
          <h2 className="center-text">
            Why choose our mixed recycling collection service?
          </h2>
          <div className="ContainerBulletPoints">

          <div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">A cost-effective recycling service</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">
                  Regular, reliable and hassle-free collections
                </span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Excellent customer service</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">
                  Guaranteed compliance with regulations
                </span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">One invoice for all your services</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Range of user-friendly containers</span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">A free, no-obligation quote</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Free advice on how to recycle more</span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
          </div>
        </div>
        </div>



        <div className="WhatsPermittedContainer">
          <h2 className="center-text">What is allowed?</h2>
          <div className="ContainerBulletPoints">

          <div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Plastic bottles</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Card</span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>

            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Newspapers and magazines</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Cans</span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>

            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Paper</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Shredded paper</span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>

        </div>
        </div>
        </div>



        <div className="ContainerSizesContainer ">
          <h2 className="center-text">Container sizes</h2>

          <div className="col-xs-2 col-sm-8 col-md-8 col-lg-8 OuterContainerSizesContainer">
            <div className="row">
              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-mixedrecycling-240L img-fluid"
                  alt="240L"
                >
                  {" "}
                </div>
                <h5 className="card-title">240L</h5>
              </div>

              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-mixedrecycling-360L img-fluid"
                  alt="360L"
                >
                  {" "}
                </div>
                <h5 className="card-title">360L</h5>
              </div>

              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-mixedrecycling-660L img-fluid"
                  alt="660L"
                >
                  {" "}
                </div>
                <h5 className="card-title">660L</h5>
              </div>

              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-mixedrecycling-1100L img-fluid"
                  alt="1100L"
                >
                  {" "}
                </div>
                <h5 className="card-title">1100L</h5>
              </div>

              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-mixedrecycling-FEL6CY img-fluid"
                  alt="FEL 6CY"
                >
                  {" "}
                </div>
                <h5 className="card-title">FEL 6CY</h5>
              </div>

              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-mixedrecycling-FEL8CY img-fluid"
                  alt="FEL 8CY"
                >
                  {" "}
                </div>
                <h5 className="card-title">FEL 8CY</h5>
              </div>

              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-mixedrecycling-FEL10CY img-fluid"
                  alt="FEL 10CY"
                >
                  {" "}
                </div>
                <h5 className="card-title">FEL 10CY</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MixedRecycling;

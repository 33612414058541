import React, { Component } from 'react';

class WasteTopBanner extends Component {

  constructor(){
    const originUrl= window.location.origin ;

    super();
    this.state={
      origiUrl: window.location.origin,
      //Set value for deeplink
      baseUrl: originUrl+'/link/quote?postcode=',
      postcode:'',
      postcodeError:'',
      quoteContactName:'',
      quoteContactNameError:'',
      quoteCompanyName:'',
      quoteCompanyNameError:'',
      quoteEmailAddress:'',
      quoteEmailAddressError:'',
      quotePhoneNumber:'',
      quotePhoneNumberError:'',
      isButtonDisabled: '',
    };

    this.handlePostcodeChange = this.handlePostcodeChange.bind(this);
    this.handleQuoteContactNameChange = this.handleQuoteContactNameChange.bind(this);
    this.handleQuoteCompanyNameChange = this.handleQuoteCompanyNameChange.bind(this);
    this.handleQuoteEmailAddressChange = this.handleQuoteEmailAddressChange.bind(this);
    this.handleQuotePhoneNumberChange = this.handleQuotePhoneNumberChange.bind(this);
    this.getQuote = this.getQuote.bind(this);
  }

  componentDidMount(){
    this.setState({
      isButtonDisabled: undefined,
      postcode:'',
      quoteContactName:'',
      quoteCompanyName:'',
      quoteEmailAddress:'',
      quotePhoneNumber:''
    }); 
  }

  handlePostcodeChange(e){
    this.setState({
      postcode:e.target.value
    })
  }

  handleQuoteContactNameChange(e){
    this.setState({
      quoteContactName:e.target.value
    })
  }

  handleQuoteCompanyNameChange(e){
    this.setState({
      quoteCompanyName:e.target.value
    })
  }

  handleQuoteEmailAddressChange(e){
    this.setState({
      quoteEmailAddress:e.target.value
    })
  }

  handleQuotePhoneNumberChange(e){
    this.setState({
      quotePhoneNumber:e.target.value
    })
  }
  
  getQuote = (e) => {
    e.preventDefault();
    this.setState({isButtonDisabled: 'true'});  

    const err = this.validate();

    if(!err){
      const { baseUrl, postcode, quoteContactName, quoteCompanyName, quoteEmailAddress, quotePhoneNumber  } = this.state;
      const url = `${baseUrl}${postcode}&quoteContactName=${quoteContactName}&quoteCompanyName=${quoteCompanyName}&quoteEmailAddress=${quoteEmailAddress}&quotePhoneNumber=${quotePhoneNumber}`;
      window.open(url, "_self");

      this.setState({
        isButtonDisabled: undefined
      });  
    }
    else{
      this.setState({
        isButtonDisabled: undefined
      });  
    }
  }

  validate = () =>{
    
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var postcode = require('postcode-validator');
    //this.setState((postcodeError) => ({value: ''}));

    let isError = false;
    const errors = {};

    let isPostcodeValid = postcode.validate(this.state.postcode.trim(), 'UK');
    if (!isPostcodeValid){
      isError = true;
      errors.postcodeError = 'Please provide a valid postcode.';
    }
    else errors.postcodeError = "";

    if (this.state.quoteContactName.trim().length < 1) {
      isError = true;
      errors.quoteContactNameError = "Please provide a contact name.";
    } 
    else errors.quoteContactNameError = "";

    if (this.state.quoteCompanyName.trim().length < 1) {
      isError = true;
      errors.quoteCompanyNameError = "Please provide a company name.";
    } 
    else errors.quoteCompanyNameError = "";

    if (!re.test(String(this.state.quoteEmailAddress.trim()).toLowerCase())) {
      errors.quoteEmailAddressError = "Please provide a valid email address.";
      isError = true;
    } 
    else errors.quoteEmailAddressError = "";

    if (this.state.quotePhoneNumber.trim().length < 10) {
      errors.quotePhoneNumberError = "Please provide a valid phone number.";
      isError = true;
    } 
    else errors.quotePhoneNumberError = "";

    if (isError){
      this.setState({
        ...this.state,
        ...errors
      });
    }

    return isError;
  }

  render() { return (

    <div className="AllWasteTopBannerContainer col-12">
    <div className="row">

        <div className="panelWrapper">
        <div className="otherPagePanels">

        <h1 className="benefitsTitle_otherPages">Get an instant quote</h1>
        <form onSubmit={this.getQuote}>
          
        <div className="input-group">
          <div className="glyphDiv">
            <div className="glyphicon glyphicon glyphicon-user"> </div>
          </div>
          <label className="hide" htmlFor="quoteContactName">Contact name</label> 
          <input type="text" className={this.state.quoteContactNameError ? "textboxes validError" : "textboxes"} name="quoteContactName" placeholder="Enter your contact name" value={this.state.quoteContactName} onChange={this.handleQuoteContactNameChange}/>          
        </div>  
        <div className="validationErrorQuoteFieldsRequired">{this.state.quoteContactNameError}</div>

        <div className="input-group">
          <div className="glyphDiv">
            <div className="glyphicon glyphicon glyphicon-home"> </div>
          </div>
          <label className="hide" htmlFor="quoteCompanyName">Company name</label> 
          <input type="text" className={this.state.quoteCompanyNameError ? "textboxes validError" : "textboxes"} name="quoteCompanyName" placeholder="Enter your company name" value={this.state.quoteCompanyName} onChange={this.handleQuoteCompanyNameChange}/>          
        </div>
        <div className="validationErrorQuoteFieldsRequired">{this.state.quoteCompanyNameError}</div>

        <div className="input-group">
          <div className="glyphDiv">
            <div className="glyphicon glyphicon glyphicon-envelope"> </div>
          </div>
          <label className="hide" htmlFor="quoteEmailAddress">Email address</label> 
          <input type="email" className={this.state.quoteEmailAddressError ? "textboxes validError" : "textboxes"} name="quoteEmailAddress" placeholder="Enter your email address" value={this.state.quoteEmailAddress} onChange={this.handleQuoteEmailAddressChange}/>          
        </div>
        <div className="validationErrorQuoteFieldsRequired">{this.state.quoteEmailAddressError}</div>

        <div className="input-group">
          <div className="glyphDiv">
            <div className="glyphicon glyphicon glyphicon-phone-alt"> </div>
          </div>
          <label className="hide" htmlFor="quotePhoneNumber">Phone number</label> 
          <input type="text" pattern="[0-9]*" className={this.state.quotePhoneNumberError ? "textboxes validError" : "textboxes"} name="quotePhoneNumber" placeholder="Enter your phone number" value={this.state.quotePhoneNumber} onChange={this.handleQuotePhoneNumberChange}/>          
        </div>  
        <div className="validationErrorQuoteFieldsRequired">{this.state.quotePhoneNumberError}</div>

        <div className="input-group">    
          <div className="glyphDiv">
            <div className="glyphicon glyphicon-map-marker"> </div>
          </div> 
          <label className="hide" htmlFor="postcode">Postcode</label> 
          <input type="text" className={this.state.postcodeError ? "textboxes validError" : "textboxes"} name="postcode" placeholder="Enter your postcode" value={this.state.postcode} onChange={this.handlePostcodeChange}/>          
        </div> 
        <div className="validationErrorPostcode">{this.state.postcodeError}</div>
        
        <div className="getQuoteBtn">
        <input type="submit" name="button1" className="btn btn-block quote-btn gtm-getaquote" value={this.state.isButtonDisabled ? 'Checking...': 'Get a quote'} disabled={this.state.isButtonDisabled}  />
        </div>
        </form>
      </div>
      </div>
    </div>
            </div>
); } }

export default WasteTopBanner;

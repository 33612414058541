import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class PageSelector extends Component {

    handleGeneralWasteRedirect = () => {
        this.props.history.push('/general-waste')
        window.scrollTo(0,0);
    }

    handleMixedRecyclingRedirect = () => {
        this.props.history.push('/mixed-recycling')
        window.scrollTo(0,0);
    }

    handleGlassRecyclingRedirect = () => {
        this.props.history.push('/glass-recycling')
        window.scrollTo(0,0);
    }

    handleFoodWasteRedirect = () => {
        this.props.history.push('/food-waste')
        window.scrollTo(0,0);
    }

    render() {
        return (

    <div className="pageSelector">
            <div className="pageSelectorContainer">

                <div className="row">
                    <div className="card col-lg-3 col-md-4 col-sm-6 " onClick={this.handleGeneralWasteRedirect}>
                        <div className="card-img-generalwaste img-fluid" alt="Card image cap"> </div>
                        <div className="card-body">
                            <h5 className="card-title">General waste</h5>
                            <div className="line"> </div>
                            <p className="card-text">SUEZ offers a reliable and cost-effective commercial waste collection service for all non-recyclable trade waste.</p>
                        </div>
                        <div className="cardfooter">
                            <input type="button" name="button1" onClick={this.handleGeneralWasteRedirect} className="btn btn-block quote-btn" value="Find out more">
                            </input>
                        </div>
                    </div>

                    <div className="card col-lg-3 col-md-4 col-sm-6" onClick={this.handleMixedRecyclingRedirect}>
                        <div className="card-img-mixedrecycling img-fluid" alt="Card image cap"></div>
                        <div className="card-body">
                            <h5 className="card-title">Mixed recycling</h5>
                            <div className="line"> </div>
                            <p className="card-text">SUEZ offers a convenient and cost-effective commercial mixed recycling collection service.</p>
                        </div>
                        <div className="cardfooter">
                            <input type="button" name="button2" onClick={this.handleMixedRecyclingRedirect} className="btn btn-block quote-btn" value="Find out more" />
                        </div>
                    </div>

                    <div className="card col-lg-3 col-md-4 col-sm-6" onClick={this.handleGlassRecyclingRedirect}>
                        <div className="card-img-glassrecycling img-fluid" alt="Card image cap"></div>
                        <div className="card-body">
                            <h5 className="card-title">Glass recycling</h5>
                            <div className="line"> </div>
                            <p className="card-text">SUEZ offers a reliable and cost-effective commercial glass recycling service.</p>
                        </div>
                        <div className="cardfooter">
                            <input type="button" name="button3" onClick={this.handleGlassRecyclingRedirect} className="btn btn-block quote-btn" value="Find out more" />
                        </div>
                    </div>

                    <div className="card col-lg-3 col-md-4 col-sm-6" onClick={this.handleFoodWasteRedirect}>
                        <div className="card-img-foodwaste img-fluid" alt="Card image cap"></div>
                        <div className="card-body">
                            <h5 className="card-title">Food waste </h5>
                            <div className="line"> </div>
                            <p className="card-text">SUEZ offers a reliable and convenient commercial food waste collection service.</p>
                        </div>
                        <div className="cardfooter">
                            <input type="button" name="button4" onClick={this.handleFoodWasteRedirect} className="btn btn-block quote-btn" value="Find out more" />
                        </div>
                    </div>

                </div>
                </div>
            </div>
        );
    }
}

export default withRouter(PageSelector);

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import FoodWasteTopBanner from "../topbannerComponent/foodwastetopbanner";
import WhyChooseUs from "../bodyComponent/whychooseus";
import Cookies from 'js-cookie';

class Foodwaste extends Component {
  componentDidMount() {
    var data = {};
    data.event = "VirtualPageview";
    data.virtualPageURL = "/landing/food";
    data.virtualPageTitle = "Landing Page (Food)";
    // Enables the data layer if user has consented to enabling GTM, otherwise does not run it.
    // Data layer is used to enable GTM
    Cookies.get('statisticsCookiesConsent') == 'true' ? window.dataLayer.push(data) : console.log("")
  }

  render() {
    return (
      <div>
        <FoodWasteTopBanner />
        <div className="">
          <Helmet>
            <title>
            Food Waste │ SUEZ UK Commercial Recycling and Waste Collection
            </title>
            <meta
              name="description"
              content="Commercial food waste collection services for businesses. Competitive pricing and environmentally friendly. Apply for instant general waste collection quote online today."
            />
            <link rel="canonical" href="https://quotes.suez.co.uk/food-waste"/>
            <meta
              name="keywords"
              content="food waste, business food waste, food waste collectors, business waste, food waste collection, food waste bin, food waste collectors, trade waste collection, food waste collectors, food waste collection quote, food waste company, food, waste quote, suez food waste, suez recycling and recovery, Commercial food waste"
            />
                    
          </Helmet>

          <div className="MainBodyTextContainer">
            <h1 className="center-text">Food waste</h1>
            <div className="aligntextleft">
              <div className="">
                <div className="row">
                  <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
                  <div className="col-md-10 col-md-10 col-xs-10 col-sm-10">
                  <p className="center-text boldfont">
                  Disposing of food waste along with general waste is throwing away not only a natural resource, but also your money. Our food waste collection service will help you avoid unnecessary costs affecting both your business and the environment.
                    </p>
                    <p>
                    SUEZ recycling and recovery UK provides a cost-effective, reliable and convenient food waste collection service tailored to the individual needs of your business.
                    </p>
                    <p>
                    The food waste that we collect is recycled or turned into renewable energy. 
Our composting centres will turn your waste into bio-fertiliser and soil conditioner. Alternatively, food waste processed in anaerobic digestion facilities will generate energy, while avoiding carbon emissions. Where possible, none of your food waste will go to landfill.

                    </p>
                  </div>
                  <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
                </div>
              </div>
            </div>
          </div>

          <div className="WhyUsContainer">
            <h2 className="center-text">
              Why choose our food waste collection service?
            </h2>
            <div className="ContainerBulletPoints">
            <div>
              <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    A cost-effective recycling service
                  </span>
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Regular, reliable and hassle-free collections
                  </span>
                </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Excellent customer service</span>
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Guaranteed compliance with regulations
                  </span>
                </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    One invoice for all your services
                  </span>
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Range of user-friendly containers
                  </span>
                </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">A free, no-obligation quote</span>
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Free advice on how to recycle more
                  </span>
                </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />

              </div>
            </div>
          </div>

          <div className="WhatsPermittedContainer">
            <h2 className="center-text">What is allowed?</h2>
            <div className="ContainerBulletPoints">
            <div>

              <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Biodegradable and compostable bio-bags
                  </span>
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Cooked meat, fish and bones</span>
                </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Rice, pasta and cereal</span>
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Bread, cakes and pastries</span>
                </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Tea bags and coffee grounds</span>
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Dairy products, eggshells</span>
                </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Fruit</span>
                </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Vegetables</span>
                </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>
            </div>
          </div>
        </div>

        <div className="ContainerSizesContainer ">
          <h2 className="center-text">Container sizes</h2>

          <div className="col-xs-4 col-sm-9 col-md-6 col-lg-4 OuterContainerSizesContainer">
            <div className="row">
              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-foodrecycling-140L img-fluid"
                  alt="140L"
                >
                  {" "}
                </div>
                <h5 className="card-title">140L</h5>
              </div>

              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-foodrecycling-240L img-fluid"
                  alt="240L"
                >
                  {" "}
                </div>
                <h5 className="card-title">240L</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>

    );
  }
}
export default Foodwaste;

import React, { Component } from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import CookiePolicypdf from "../../documents/SUEZ Cookie Policy.pdf";

class cookieConsent extends Component {
  constructor() {
    //const originUrl = window.location.origin;
    super();
    this.state = {
      origiUrl: window.location.origin,
      //Set value for deeplink
      //baseUrl: originUrl + '/link/policydocuments?GDPRDocument=',
      attribute: ""
    };

    this.state = {
      cookieConsentSettingsVisible: false,
      cookieConsentSettingsAlreadyShown: false,
      mandatoryCookiesPreference: true,
      statisticsCookiesPreference: true,
      marketingCookiesPreference: true,
    };
  }

  cookieConsentSettingsVisibility = () => {
    this.setState({
      cookieConsentSettingsVisible: !this.state.cookieConsentSettingsVisible
    });

    if (this.state.cookieConsentSettingsAlreadyShown == false) {
      this.setState({ cookieConsentSettingsAlreadyShown: true });
      this.setState({ marketingCookiesPreference: false });
    }
  };

  mandatoryCookiesSelected = () => {
    this.setState({
      mandatoryCookiesPreference: !this.state.mandatoryCookiesPreference
    });
  };

  statisticsCookiesSelected = () => {
    this.setState({
      statisticsCookiesPreference: !this.state.statisticsCookiesPreference
    });
  };

  marketingCookiesSelected = () => {
    this.setState({
      marketingCookiesPreference: !this.state.marketingCookiesPreference
    });
  };

  setMandatoryCookies = () => {
    Cookies.set("mandatoryCookiesConsent", "true", { expires: 365 }); // Sets and stores the consent given by users for mandatory cookies, which is then read later to enable these cookies.
    // Enter mandatory cookies code here if required, The current mandatory cookies are being set by default i.e., they are session cookies, user-profile cookies, etc.,
  };

  setStatisticsCookies = () => {
    Cookies.set("statisticsCookiesConsent", "true", { expires: 365 }); // Sets and stores the consent given by users for statistics cookies, which is then read later to enable these cookies.
    // All tracking/cookies are placed in App.js file, so that they excute on each page refresh and on all pages.
  };

  setMarketingCookies = () => {
    Cookies.set("marketingCookiesConsent", "true", { expires: 365 }); // Sets and stores the consent given by users for marketing cookies, which is then read later to enable these cookies.
    // All tracking/cookies are placed in App.js file, so that they excute on each page refresh and on all pages.
  };

  setSelectedCookies = () => {
    {
      this.state.mandatoryCookiesPreference
        ? this.setMandatoryCookies()
        : console.log("");
    }
    {
      this.state.statisticsCookiesPreference
        ? this.setStatisticsCookies()
        : console.log("");
    }
    {
      this.state.marketingCookiesPreference
        ? this.setMarketingCookies()
        : console.log("");
    }
    window.location.reload(); // Refresh the page(s) to allow the changes to take affect
  };

  getCookieConsent = e => {
    e.preventDefault();
    window.open(CookiePolicypdf);
  };

  render() {
    return (
      <div>
        <CookieConsent
          location="bottom"
          cookieName="SuezCookieConsent"
          style={{ background: "#3D466C" }}
          expires={365}
          className="consentBanner"
          debug={false} // True to show cookie consent banner on each page refresh, even after accept, false to turn it off.
          buttonText={
            this.state.mandatoryCookiesPreference == true &&
            this.state.statisticsCookiesPreference == true &&
            this.state.marketingCookiesPreference == true
              ? "Accept All"
              : "Accept Selected"
          }
          buttonStyle={
            this.state.mandatoryCookiesPreference == true &&
            this.state.statisticsCookiesPreference == true &&
            this.state.marketingCookiesPreference == true
              ? {
                  background: "#9BCD41",
                  color: "#3D466C",
                  fontWeight: "700",
                  fontSize: "16px",
                  minWidth: "140px",
                  cursor: "pointer",
                  paddingLeft: "58.5px",
                  paddingRight: "58.5px"
                }
              : {
                  background: "#9BCD41",
                  color: "#3D466C",
                  fontWeight: "700",
                  fontSize: "16px",
                  minWidth: "140px",
                  cursor: "pointer",
                  paddingLeft: "39.5px",
                  paddingRight: "39.5px"
                }
          }
          onAccept={() => {
            this.setSelectedCookies();
          }}
          buttonText={
            this.state.mandatoryCookiesPreference == true &&
            this.state.statisticsCookiesPreference == true &&
            this.state.marketingCookiesPreference == true
              ? "Accept all"
              : "Accept selected"
          }
        >
          <div>
            <button
              className={
                this.state.mandatoryCookiesPreference == true &&
                this.state.statisticsCookiesPreference == true &&
                this.state.marketingCookiesPreference == true
                  ? "cookieConsentSettingsBtn"
                  : "cookieConsentSettingsBtnSelected"
              }
              onClick={() => this.cookieConsentSettingsVisibility()}
            >
              Manage preferences
            </button>

            <div>
              Our website uses cookies to help improve user experience, site
              statistics, analytics and marketing. To learn more see our
              <span
                className="consentBanner"
                style={{
                  fontSize: "20.5px",
                  marginLeft: "5px",
                  marginRight: "5px",
                  textDecoration: "underline",
                  cursor: "pointer"
                }}
                onClick={this.getCookieConsent}
              >
                cookie policy
              </span>
              or{" "}
              <div
                className="cookieConsentSettingsText"
                onClick={() => this.cookieConsentSettingsVisibility()}
              >
                manage your preferences.
              </div>{" "}
            </div>

            {this.state.cookieConsentSettingsVisible ? (
              <div className="cookieConsentSettingsContainer">
                <span className="cookieConsentSettingsSelection">
                  <input
                    className="cookieConsentSettingsSelection"
                    type="checkbox"
                    defaultChecked={this.state.mandatoryCookiesPreference}
                    disabled="disabled"
                    id="mandatory"
                    onChange={this.mandatoryCookiesSelected}
                  />
                  <label for="mandatory"></label>{" "}
                  <label for="mandatory">Mandatory (Required)</label>{" "}
                </span>

                <span className="cookieConsentSettingsSelection">
                  <input
                    className="cookieConsentSettingsSelection"
                    type="checkbox"
                    defaultChecked={this.state.statisticsCookiesPreference}
                    disabled="disabled"
                    id="statistics"
                    onChange={this.statisticsCookiesSelected}
                  />
                  <label for="statistics"></label>
                  <label for="statistics">Statistics (Required)</label>
                </span>

                <span className="cookieConsentSettingsSelection">
                  <input
                    className="cookieConsentSettingsSelection"
                    type="checkbox"
                    defaultChecked={this.state.marketingCookiesPreference}
                    id="marketing"
                    onChange={this.marketingCookiesSelected}
                  />
                  <label for="marketing"></label>
                  <label for="marketing">Marketing</label>
                </span>
              </div>
            ) : null}
          </div>
        </CookieConsent>
      </div>
    );
  }
}

export default cookieConsent;
import React, { Component } from "react";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

//Components
import Header from "./components/headerComponent/header";
import Subheader from "./components/subheaderComponent/subheader";
import Footer from "./components/footerComponent/footer_internal";
import homepage from "./components/pages/homepage";
import generalwaste from "./components/pages/general-waste";
import glassrecycling from "./components/pages/glass-recycling";
import mixedrecycling from "./components/pages/mixed-recycling";
import foodwaste from "./components/pages/food-waste";
import contactus from "./components/pages/contact-us";
import Faq from "./components/pages/faq";
import CookieConsent from "./components/cookieConsentComponent/cookieconsent";
import aboutus from "./components/pages/about-us";
// import "./Assets/css/default.min.css";
import "./Assets/css/default.css";
import Cookies from "js-cookie";

// Importing the various tracking tools as react packages.
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import { hotjar } from "react-hotjar";
import BingTracking from "react-tracking-bing";

// On launch of the website, mandatory and statistics cookies are automtically set which in return triggers the associated cookies to become active.
Cookies.set("mandatoryCookiesConsent", "true", { expires: 365 }); // Sets and stores the consent given by users for mandatory cookies, which is then read later to enable these cookies.
Cookies.set("statisticsCookiesConsent", "true", { expires: 365 }); // Sets and stores the consent given by users for statistics cookies, which is then read later to enable these cookies.

// Defining the statistics cookies and setting them if user consent is given
if (Cookies.get("statisticsCookiesConsent") == "true") {
  // ********************* Start Google Tag Manager ********************* //
  const tagManagerArgs = {
    gtmId: "GTM-PM6Z27W"
  };
  TagManager.initialize(tagManagerArgs);
  // ********************* End Google Tag Manager ********************* //

  // ********************* Start Hotjar ********************* //
  // hjid: Stands for 'Hotjar ID' - Your site's ID. This is the ID which tells Hotjar which site settings it should load and where it should save the data collected.
  // hjsv: Stands for 'Hotjar Snippet Version' - The version of the Tracking Code you are using. This is only needed if Hotjar ever updates the Tracking Code and needs to discontinue older ones. Knowing which version your site includes allows hotjar team to contact you and inform you accordingly.
  hotjar.initialize(1302898, 6);
  // ********************* End Hotjar ********************* //

  // ReactJS Bing Pixel Tracking Code, JavaScript version is currently being used in the index.html file
  // // ********************* Start Bing Pixel Tracking ********************* //
  // // Custom tracking id
  // BingTracking.initialize("15169929");

  // // After initializing page views can be tracked like this:
  // BingTracking.trackPageview();

  // // There is also the option to track custom events with event action, category, label and value:
  // // BingTracking.trackEvent(eventAction, eventCategory, eventLabel, eventValue);

  // // There is also a method to expose the raw uetq tracking object api from the window:
  // BingTracking.getTracker();
  // // ********************* End Bing Pixel Tracking ********************* //
}

// Defining the marketing cookies and setting them if user consent is given
if (Cookies.get("marketingCookiesConsent") == "true") {
  // ********************* Start Facebook Pixel Code ********************* //
  const advancedMatching = { em: "" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match *** Put email inside "" if required.
  const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: false // enable logs
  };
  ReactPixel.init("181953299073281", advancedMatching, options);
  ReactPixel.pageView(); // For tracking page view
  // ReactPixel.track(event, data); // For tracking default events, more info about events and data https://developers.facebook.com/docs/ads-for-websites/pixel-events/v2.9
  // ReactPixel.trackCustom(event, data); // For tracking custom events
  // ********************* End Facebook Pixel Code ********************* //
}

const NoMatch = () => <div>This page connot be found</div>;

class App extends Component {
  render() {
    return (
      <div>
        <Router onUpdate={() => window.scrollTo(0, 0)}>
          <div className="App">
            <Header />
            <Subheader />
            <Switch>
              <Route exact path="/" component={homepage} />
              <Route exact path="/index.html" component={homepage} />
              <Route exact path="/food-waste" component={foodwaste} />
              <Route exact path="/general-waste" component={generalwaste} />
              <Route exact path="/glass-recycling" component={glassrecycling} />
              <Route exact path="/mixed-recycling" component={mixedrecycling} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/contact-us" component={contactus} />
              <Route exact path="/about-us" component={aboutus} />
              <Redirect from="*" to="/" />
            </Switch>
            <CookieConsent />
            <Footer />
          </div>
        </Router>
      </div>
    );
  }
}

export default App;

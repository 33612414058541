import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

class Sidebar extends Component {

  constructor(props) {
  super(props);

  this.setWrapperRef = this.setWrapperRef.bind(this);
  this.handleClickOutside = this.handleClickOutside.bind(this);

  this.state = {addClass: false}
}

componentDidMount() {
  document.addEventListener('mousedown', this.handleClickOutside);
}

componentWillUnmount() {
  document.removeEventListener('mousedown', this.handleClickOutside);
}

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

    /**
   * Minimise if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.settoggle();
    }
  }

  toggle = () => {
    this.setState({addClass: !this.state.addClass});
  }

  

  settoggle(){
    this.setState({addClass: false});
  }

  render() {

    let dropDownClass = ["sidebar"];
    if(this.state.addClass) {
      dropDownClass.push('openSidebar');
    }

    return (
  <div ref={this.setWrapperRef}>
    <div className={dropDownClass.join(' ')} onClick={this.toggle.bind(this)}>
        <div>
          <span>Our services</span>
        </div>
          <nav className="items-wrapper">

            <ul className="items items-level-1">

              <NavLink to="/general-waste" className="dropdownitem" activeClassName="is-active">
              <li className="label"> General waste </li>
              </NavLink>

              <NavLink to="/mixed-recycling" className="dropdownitem" activeClassName="is-active">
              <li className="label"> Mixed recycling </li>
              </NavLink>

              <NavLink to="/glass-recycling" className="dropdownitem" activeClassName="is-active">
              <li className="label"> Glass recycling </li>
              </NavLink>

              <NavLink to="/food-waste" className="dropdownitem" activeClassName="is-active">
              <li className="label"> Food waste </li>
              </NavLink>

              <NavLink to="/about-us" className="dropdownitem" activeClassName="is-active">
              <li className="label"> About us </li>
              </NavLink>

              <NavLink to="/faq" className="dropdownitem" activeClassName="is-active">
              <li className="label"> FAQ </li>
              </NavLink>

            </ul>
          </nav>
        </div>
    </div>

    );
  }
}

export default Sidebar;

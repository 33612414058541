import React, { Component } from "react";
import HomePageTopBanner from "../topbannerComponent/homepagetopbanner";
import PageSelector from "../bodyComponent/pageselector";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      origiUrl: window.location.origin,
    };
  }
  componentDidMount() {
    var data = {};
    data.event = "VirtualPageview";
    data.virtualPageURL = "/landing";
    data.virtualPageTitle = "Landing Page";
    // Enables the data layer if user has consented to enabling GTM, otherwise does not run it.
    // Data layer is used to enable GTM
    Cookies.get("statisticsCookiesConsent") == "true"
      ? window.dataLayer.push(data)
      : console.log("");
  }

  makePhoneCall = () => {
    window.open("tel:+448000830504");
  };

  render() {
    return (
      <div>
        <HomePageTopBanner postcode="" />
        <Helmet>
          <title>SUEZ Instant Recycling and Waste Collection Quote</title>
          <meta
            name="description"
            content="Commercial recycling and waste collection services for businesses. Competitive pricing and environmentally friendly. Apply for instant waste collection quote online today."
          />
          <link rel="canonical" href="https://quotes.suez.co.uk/homepage" />
          <meta
            name="keywords"
            content="commercial recycling, business recycling, waste collectors, business waste, bin collection, commercial waste bin, bin collectors, trade waste collection, waste collectors, waste bin collection, recycling collection, recycling company, recycling quote, rubbish collection, waste collection, waste collection quote, suez, suez recycling and recovery, commercial waste collection, general waste, mixed recycling, glass recycling, food waste, general waste collection, mixed, recycling collection, glass recycling collection, food waste collection, local waste collection, business waste collection, local recycling collection, business recycling collection, commercial recycling collection"
          />
        </Helmet>

        <div className="MainBodyTextContainer">
          <h1 className="center-text why-us-heading">
            Why choose SUEZ recycling and recovery UK?
          </h1>
          <div className="aligntextleft">
            <div className="">
              <div className="row">
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
                <div className="col-md-10 col-md-10 col-xs-10 col-sm-10">
                  <p className="center-text boldfont">
                    SUEZ recycling and recovery UK provides a reliable and
                    cost-effective service that takes care of all your business
                    recycling and waste needs.
                  </p>
                  <p>
                    Our collection and disposal services are tailored to suit
                    your requirements, leaving you to run your business
                    efficiently, and with the peace of mind that your waste
                    management is handled in a safe, cost-effective and
                    compliant manner.
                  </p>
                  <p>
                    We serve more than 30,000 small and medium businesses and
                    operate from over 300 locations across the UK.
                  </p>
                  <p>
                    Whether you require a weekly collection from one premise, or
                    you produce many different waste streams across many sites,
                    we provide you with the most appropriate solution.
                  </p>
                  <p>
                    We will help you choose the right collection frequency and
                    the waste containers to suit the waste volumes and the space
                    at your premises.
                  </p>
                  <p>
                    We help our customers recycle as much as possible, but not
                    all waste can be recycled. If your waste can’t be recycled
                    it will always be disposed of in a safe and compliant way.
                  </p>
                </div>
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
              </div>
            </div>
          </div>
        </div>
        <div className="WhyUsContainer">
          <h2 className="center-text">Benefits</h2>
          <div className="ContainerBulletPoints">
            <div>
              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Environmentally friendly</span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Hassle-free collection services</span>
                </div>
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">Excellent customer service</span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">A free, no-obligation quote</span>
                </div>
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>

              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    Range of cost-effective recycling and waste management
                    services
                  </span>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item">
                    One invoice for all your services
                  </span>
                </div>
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>
            </div>
          </div>
        </div>
        <div className="pageselector">
          <PageSelector />
        </div>
        <div className="homepagecontactus">
          <p className="center-text">
            Call our team on{" "}
            <a href="javascript:void(0)" onClick={this.makePhoneCall}>
              {" "}
              0800 083 0504{" "}
            </a>{" "}
            or use the <a href="/contact-us">‘contact us’ </a> form to discuss
            your commercial recycling and waste collection requirements.
          </p>
        </div>
      </div>
    );
  }
}

export default Homepage;

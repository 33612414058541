import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import FoodWasteTopBanner from "../topbannerComponent/faqtopbanner";
import FaqTopBanner from "../topbannerComponent/faqtopbanner";
import Cookies from 'js-cookie';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = { addClass: false };
  }
  componentDidMount() {
    var data = {};
    data.event = "VirtualPageview";
    data.virtualPageURL = "/landing/faq";
    data.virtualPageTitle = "Landing Page (Faq)";
    // Enables the data layer if user has consented to enabling GTM, otherwise does not run it.
    // Data layer is used to enable GTM
    Cookies.get('statisticsCookiesConsent') == 'true' ? window.dataLayer.push(data) : console.log("")
  }

  toggle(event) {
    //this.setState({addClass: !this.state.addClass});
    event.target.parentNode.classList.toggle("openDropDown");
  }

  handleContactUsRedirect = () => {
    this.props.history.push("/contact-us");
  };

  makePhoneCall = () => {
    window.open("tel:+448000830504");
  };

  render() {
    return (
      <div className="FaqContentbackground">
        <FaqTopBanner />

        <div className="FaqContent">
          <Helmet>
            <title>Frequently Asked Questions │ SUEZ UK Commercial Recycling and Waste Collection</title>
            <meta
              name="description"
              content="If you have any questions about our recycling and waste collection services please visit our frequently asked questions. Alternatively, call us on 0800 083 0504. We are here to help."
            />
            <link rel="canonical" href="https://quotes.suez.co.uk/faq"/>
            <meta
              name="keywords"
              content="SUEZ recycling FAQ, waste collection FAQ, SUEZ recycling collection FAQ, SUEZ waste collection FAQ, business waste collection FAQ, business waste collection questions, frequently asked recycling questions, frequently asked waste, collection questions, suez frequently asked questions, waste collection help, waste services help, recycling advice help, suez waste"
            />
                    
          </Helmet>
          <div className="MainBodyTextContainer">
          <h1 className="center-text">Frequently Asked Questions</h1>
          </div>
          <div className="FAQDropDowns">
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  How long is a service agreement for?{" "}
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>The minimum service agreement is 24 months.</p>
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">Are the bins lockable?</div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>
                    Not all of our containers are lockable. The 660L, 1100L
                    containers and all other larger containers do have a locking
                    function.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  Can I change my collection frequency?
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>
                    You can increase the service frequency of your recycling and
                    waste collection service whenever required, however, this is
                    dependent on the local branch servicing the area that day.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  Is there a delivery charge for the bins?
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>No, we do not charge to deliver the containers.</p>
                  <p>Additional charges may occur for any container changes within the agreed contract length.</p>
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  Is there a weight allowance for the bins?
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>
                    {" "}
                    Yes, there is a weight allowance for the contents of the
                    bin.
                  </p>
                  <p>
                    {" "}
                    Our vehicles are fitted with certified scales which weigh
                    the containers upon collection to ensure accurate data
                    collection. This can be increased at an extra cost but the
                    standard weight allowances for each container is as follows:
                  </p>
                  <img src="/weightRangeforFAQ.png" alt="Suez Weight Range FAQ" width="100%" height="100%" />
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  What is the excess charge for additional weight?
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>Any excess weight will be charged at 0.15p per kg.</p>
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  How long does it take for my bins to be delivered?
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>
                    Once we have a signed Service Agreement, Risk Assessment and
                    Direct Debit Mandate it takes approximately 7 to 10 working
                    days to get the bins on-site.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  What would happen if I needed to cancel my service during the
                  24 month agreement?{" "}
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>
                    There would be a cancellation fee that would depend on how
                    far you are into your current service agreement.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  What is the Risk Assessment form for?
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>
                    The risk assessment is for our collection crew to know how
                    they will access the bins upon collection.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  Do you have any offers or discount codes?
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>
                    We are keen to offer competitive pricing, so please get in
                    touch to discuss your quote.
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv">
                  What type of waste can I put into the bin?
                </div>
                <div className="glyphDropDown"> </div>
                <div className="dropdown-content">
                  <p>See guide on following page.</p>
                  <div className="hyphenList">
                    <li>
                      {" "}
                      &#8209;
                      <NavLink
                        to="/general-waste"
                        className="linkWrapper"
                        activeClassName="is-active"
                      >
                        general waste
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      &#8209;
                      <NavLink
                        to="/mixed-recycling"
                        className="linkWrapper"
                        activeClassName="is-active"
                      >
                        mixed recycling
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      &#8209;
                      <NavLink
                        to="/glass-recycling"
                        className="linkWrapper"
                        activeClassName="is-active"
                      >
                        glass recycling
                      </NavLink>
                    </li>
                    <li>
                      {" "}
                      &#8209;
                      <NavLink
                        to="/food-waste"
                        className="linkWrapper"
                        activeClassName="is-active"
                      >
                        food waste
                      </NavLink>
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="faqcontactus">
            <p className="center-text">
            Call our team on{" "}
            <a href="javascript:void(0)" onClick={this.makePhoneCall}>
              {" "}
              0800 083 0504{" "}
            </a>{" "}
            or use the <a href="/contact-us">‘contact us’ </a> form to discuss
            your commercial recycling and waste collection requirements.
            </p>
          </div>
        </div>
        <div className="MainBodyTextContainer" />
      </div>
    );
  }
}
export default Faq;

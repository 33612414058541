import React, { Component } from "react";
import WasteTopBanner from "./wastetopbanner";

class GlassWasteTopBanner extends Component {
  render() {
    return (
      <div className="topBannerContainer">
        <div
          className="glasswaste firstSegment"
          aria-label="Glass Waste Collection"
          src="/hero-glass.jpg"
          alt="Glass Waste Collection"
        >
          <WasteTopBanner />
        </div>
      </div>
    );
  }
}

export default GlassWasteTopBanner;

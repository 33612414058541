import React, { Component } from 'react';

class WhyChooseUs extends Component { render() { return (

  <div className="midBannerContainer-bottom">
      <h2 className="center-text">Why should you choose us?</h2>


      <div >
        <div className="row">
          <div className="col-md-2 col-md-2 col-xs-0 col-sm-2"></div>
          <div className="col-md-4 col-md-4 col-xs-12 col-sm-4 "><span className="item" >One invoice for all your services</span></div>
          <div className="col-md-4 col-md-4 col-xs-12 col-sm-4"><span className="item">Free compliance check</span></div>
          <div className="col-md-2 col-md-2 col-xs-0 col-sm-2"></div>
        </div>

        <div className="row">
          <div className="col-md-2 col-md-2 col-xs-0 col-sm-2"></div>
          <div className="col-md-4 col-md-4 col-xs-12 col-sm-4"><span className="item" >A free, no-obligation quote</span></div>
          <div className="col-md-4 col-md-4 col-xs-12 col-sm-4"><span className="item">Free advice on how to recycle more</span></div>
          <div className="col-md-2 col-md-2 col-xs-0 col-sm-2"></div>
        </div>

        <div className="row">
          <div className="col-md-2 col-md-2 col-xs-0 col-sm-2"></div>
          <div className="col-md-4 col-md-4 col-xs-12 col-sm-4"><span className="item" >Excellent customer service</span></div>
          <div className="col-md-4 col-md-4 col-xs-12 col-sm-4"><span className="item">Complete range of cost-effective recycling and waste management services</span></div>
          <div className="col-md-2 col-md-2 col-xs-0 col-sm-2"></div>
        </div>

      </div>


  </div>

    );
  }
}

export default WhyChooseUs;

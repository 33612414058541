import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Subheader extends Component {
  render() {
    return (
      <div className="subheader">
        <div className="serviceItems">
            <NavLink
              to="/general-waste"
              className="linkWrapper"
              activeClassName="is-active"
            >
              <li>General waste</li>
            </NavLink>
            <div className="spaceHack"> </div>
            <NavLink
              to="/mixed-recycling"
              className="linkWrapper"
              activeClassName="is-active"
            >
              <li>Mixed recycling</li>
            </NavLink>
            <div className="spaceHack"> </div>
            <NavLink
              to="/glass-recycling"
              className="linkWrapper"
              activeClassName="is-active"
            >
              <li>Glass recycling</li>
            </NavLink>
            <div className="spaceHack"> </div>
            <NavLink
              to="/food-waste"
              className="linkWrapper"
              activeClassName="is-active"
            >
              <li>Food waste</li>
            </NavLink>
        </div>

        <div className="suezItems">
            <NavLink
              to="/about-us"
              className="linkWrapper"
              activeClassName="is-active"
            >
              <li>About us</li>
            </NavLink>

            <div className="spaceHack"> </div>
            <NavLink
              to="/faq"
              className="linkWrapper"
              activeClassName="is-active"
            >
              <li>FAQ</li>
            </NavLink>
        </div>
        </div>
    );
  }
}

export default Subheader;

import React, { Component } from "react";
import WasteTopBanner from "./wastetopbanner";

class MixedWasteTopBanner extends Component {
  render() {
    return (
      <div className="topBannerContainer">
        <div
          className="mixedwaste firstSegment"
          aria-label="Mixed Recycling Collection"
          aria-label="Mixed Recycling Services"
          src="/mixedrecyclingbanner.jpg"
          alt="Mixed Recycling Collection"
          alt="Mixed Recycling Services"
        >
          <WasteTopBanner />
        </div>
      </div>
    );
  }
}

export default MixedWasteTopBanner;

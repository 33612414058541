import React, { Component } from "react";
import AboutUsTopBanner from "../topbannerComponent/AboutUsTopBanner";
import WhyChooseUs from "../bodyComponent/whychooseus";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";

class aboutus extends Component {
  componentDidMount() {
    var data = {};
    data.event = "VirtualPageview";
    data.virtualPageURL = "/landing/whatwedo";
    data.virtualPageTitle = "Landing Page (What we do)";
    // Enables the data layer if user has consented to enabling GTM, otherwise does not run it.
    // Data layer is used to enable GTM
    Cookies.get("statisticsCookiesConsent") == "true"
      ? window.dataLayer.push(data)
      : console.log("");
  }

  toggle(event) {
    event.target.parentNode.classList.toggle("openDropDown");
  }

  makePhoneCall = () => {
    window.open("tel:+448000830504");
  };

  render() {
    return (
      <div className="">
        <AboutUsTopBanner />
        <Helmet>
          <title>
            About Us│ SUEZ UK Commercial Recycling and Waste Collection{" "}
          </title>{" "}
          <meta
            name="description"
            content="Contact SUEZ on 0800 083 0504 or email our customer service team today for your free commercial and business waste or recycling collection instant quote. "
          />
          <link rel="canonical" href="https://quotes.suez.co.uk/about-us" />
          <meta
            name="keywords"
            content="SUEZ who we are, waste collection, recycling collection, SUEZ, instant waste quote, instant recycling quote, SUEZ recycling, suez recycling and recovery, suez instant quote, waste collection near me, local waste collection, business, waste collection, commercial waste collection, local recycling collection, business recycling collection, commercial recycling collection"
          />
        </Helmet>{" "}
        <div className="whowearecontainer">
          <div className="aligntextleft">
            <h1 className="center-text"> Who we are </h1>{" "}
            <div className="">
              <div className="row">
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>{" "}
                <div className="col-md-10 col-md-10 col-xs-10 col-sm-10">
                  <p className="center-text boldfont">
                    Our vision is to live in a society where there is no more
                    waste.Over the course of a year, SUEZ handles over 10
                    million tonnes of waste in the UK making a significant and
                    growing contribution to the circular economy and
                    sustainability by recovering value from these waste
                    materials.{" "}
                  </p>{" "}
                  <p>
                    Our goal is to help create a circular economy in which
                    nearly all waste materials are given a second life and are
                    reused, recycled or recovered for their energy content.We
                    encourage our customers to recognise the value in materials
                    they throw away and to recycle as much as possible to reduce
                    not only their environmental impact but also the cost of
                    disposing of their waste.We believe waste is a resource.{" "}
                  </p>
                  <p>
                    To find out about the full range of our cost - effective and
                    reliable recycling and waste management services and how we
                    help our customers to stay compliant with the latest waste
                    legislation as we transition into to the circular economy,
                    visit{" "}
                    <a
                      href="https://www.suez.co.uk/"
                      target="_blank"
                      className="aLink"
                      activeClassName="is-active"
                    >
                      www.suez.co.uk{" "}
                    </a>{" "}
                  </p>{" "}
                </div>{" "}
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="whatwedocontainer">
          <div className="aligntextleft">
            <h1 className="center-text"> What we do </h1>{" "}
            <div className="">
              <div className="row">
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>{" "}
                <div className="col-md-10 col-md-10 col-xs-10 col-sm-10">
                  <p className="center-text boldfont">
                    SUEZ recycling and recovery UK offers a reliable and
                    convenient food waste collection service, which helps you
                    avoid the rising costs of landfill tax, as well as reduce
                    your business’ impact on the environment.{" "}
                  </p>{" "}
                  <p>
                    Have you ever wondered what happens to the waste you produce
                    after it has been collected ? We specialise in extracting
                    value from waste, which is essential to both providing you
                    with the most cost - effective and sustainable service.{" "}
                  </p>{" "}
                  <p>
                    You can find out more about the recycling and waste services
                    we provide by clicking on the below links :
                  </p>{" "}
                  <div>
                    <div className="row">
                      <div className="col-lg-2 col-md-1 col-sm-0 col-xs-0" />
                      <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                        <span>
                          <p className="whatwedopagelinkedtext">
                            {" "}
                            <a
                              href="https://www.suez.co.uk/en-gb/our-offering/businesses/what-are-you-looking-for/recycling-and-waste-management/general-waste-services"
                              target="_blank"
                            >
                              General waste{" "}
                            </a>{" "}
                          </p>{" "}
                        </span>{" "}
                      </div>{" "}
                      <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                        <span>
                          <p className="whatwedopagelinkedtext">
                            {" "}
                            <a
                              href="https://www.suez.co.uk/en-gb/our-offering/businesses/what-are-you-looking-for/recycling-and-waste-management/recycling-services"
                              target="_blank"
                            >
                              Mixed recycling{" "}
                            </a>{" "}
                          </p>{" "}
                        </span>{" "}
                      </div>{" "}
                      <div className="col-lg-2 col-md-1 col-sm-0 col-xs-0" />
                    </div>
                    <div className="row">
                      <div className="col-lg-2 col-md-1 col-sm-0 col-xs-0" />
                      <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                        <p className="whatwedopagelinkedtext">
                          {" "}
                          <a
                            href="https://www.suez.co.uk/en-gb/our-offering/businesses/what-are-you-looking-for/recycling-and-waste-management/recycling-services"
                            target="_blank"
                          >
                            Glass recycling{" "}
                          </a>{" "}
                        </p>{" "}
                      </div>{" "}
                      <div className="col-lg-4 col-md-5 col-sm-12 col-xs-12">
                        <span>
                          <p className="whatwedopagelinkedtext">
                            {" "}
                            <a
                              href="https://www.suez.co.uk/en-gb/our-offering/businesses/what-are-you-looking-for/recycling-and-waste-management/food-waste-collection"
                              target="_blank"
                            >
                              Food waste{" "}
                            </a>{" "}
                          </p>{" "}
                        </span>{" "}
                      </div>{" "}
                      <div className="col-lg-2 col-md-1 col-sm-0 col-xs-0" />
                    </div>{" "}
                  </div>{" "}
                </div>{" "}
                <div className="col-lg-2 col-md-1 col-sm-0 col-xs-0" />
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="MainBodyTextContainer" />
        <div className="MainBodyTextContainer">
          <h2 className="center-text"> Where we operate </h2>{" "}
          <div className="aligntextleft">
            <div className="justify-text">
              <div className="row">
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>{" "}
                <div className="col-md-10 col-md-10 col-xs-10 col-sm-10">
                  <p className="center-text boldfont">
                    SUEZ recycling and recovery UK is one of the UK’ s leading
                    waste management businesses.We operate from over 300
                    locations serving more than 30, 000 companies with coverage
                    across the majority of postcodes in the UK.{" "}
                  </p>{" "}
                  <p>
                    We provide local and reliable cost effective waste
                    management services for a wide range of businesses and
                    organisations taking care of all your commercial recycling
                    and waste.{" "}
                  </p>{" "}
                </div>{" "}
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>
          <div>
            <div className="ContainerBulletPoints">
              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item"> General waste collection </span>{" "}
                </div>{" "}
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item"> Mixed recycling collection </span>{" "}
                </div>{" "}
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>{" "}
              <div className="row">
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item"> Glass recycling collection </span>{" "}
                </div>{" "}
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <span className="item"> Food waste collection </span>{" "}
                </div>{" "}
                <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              </div>{" "}
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>{" "}
          </div>{" "}
        </div>
        <div className="WhereWeOperateLocationsContainer">
          <div className="WhereWeOperateLocations">
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> South West </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across the South West including:
                  </p>{" "}
                  <ul>
                    <li> Bristol </li> <li> Didcot </li> <li> Gloucester </li>{" "}
                    <li> Highbridge </li> <li> Newton Abbot </li>{" "}
                    <li> Roche </li> <li> Swindon </li> <li> Tavistock </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> South Central </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across the South Central including:
                  </p>{" "}
                  <ul>
                    <li> Aylesbury </li> <li> Dunstable </li>{" "}
                    <li> Eversley </li> <li> Farnham </li> <li> Reading </li>{" "}
                    <li> High Wycombe </li> <li> Maidenhead </li>{" "}
                    <li> Windsor </li> <li> Hitchin </li>{" "}
                    <li> Milton Keynes </li> <li> Southampton </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> South East </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across the South East including:
                  </p>{" "}
                  <ul>
                    <li> Basildon </li> <li> Burgess Hill </li>{" "}
                    <li> Chelmsford </li> <li> Hollingbourne </li>{" "}
                    <li> Newchapel </li> <li> Polegate </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> London and Greater London </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across London and Greater London including:
                  </p>{" "}
                  <ul>
                    <li> Central London </li> <li> East London </li>{" "}
                    <li> North London </li> <li> South London </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> East Midlands </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across the East Midlands including:
                  </p>{" "}
                  <ul>
                    <li> Leicester </li> <li> Nottingham </li>{" "}
                    <li> Northampton </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> West Midlands </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across the West Midlands including:
                  </p>{" "}
                  <ul>
                    <li> Birmingham </li> <li> Coventry </li>{" "}
                    <li> Craven Arms </li> <li> Stoke </li> <li> Warwick </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> Yorkshire </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across Yorkshire including:
                  </p>{" "}
                  <ul>
                    <li> Bradford </li> <li> Bridlington </li>{" "}
                    <li> Brighouse </li> <li> Dewsbury </li> <li> Leeds </li>{" "}
                    <li> Sheffield </li> <li> York </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> North West </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across the North West including:
                  </p>{" "}
                  <ul>
                    <li> Liverpool </li> <li> Manchester </li>{" "}
                    <li> Preston </li> <li> St Helens </li>{" "}
                    <li> Bromborough </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> North East </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across the North East including:
                  </p>{" "}
                  <ul>
                    <li> Middlesbrough </li> <li> Newcastle </li>{" "}
                    <li> Sunderland </li> <li> Teeside </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div>
              <div className="dropdown" onClick={this.toggle.bind(this)}>
                <div className="dropdiv"> Cumbria </div>{" "}
                <div className="glyphDropDown"> </div>{" "}
                <div className="dropdown-content">
                  <p>
                    We provide business waste management and recycling solutions
                    across Cumbria including:
                  </p>{" "}
                  <ul>
                    <li> Carlisle </li> <li> Lake District </li>{" "}
                    <li> Barrow - in -Furness </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className="whereweoperatecontactus">
          <p className="center-text">
            {" "}
            If you can 't see your area listed above, we may still be able to
            help with your waste disposal and recycling needs.Please{" "}
            <a href="/contact-us"> get in touch </a> with a member of our team
            to find out more.{" "}
          </p>{" "}
        </div>
      </div>
    );
  }
}

export default aboutus;

import React, { Component } from "react";
import WasteTopBanner from "./wastetopbanner";

class HomePageTopBanner extends Component {
  render() {
    return (
      <div className="topBannerContainer">
        <div
          className="homepage firstSegment"
          aria-label="SUEZ Recycling Collection"
          aria-label="SUEZ Waste Collection"
          aria-label="SUEZ Business Waste"
          aria-label="SUEZ Commercial Recycling"
          src="/homebanner.jpg"
          alt="SUEZ Recycling Collection"
          alt="SUEZ Waste Collection"
          alt="SUEZ Business Waste"
          alt="SUEZ Commercial Recycling"
        >
          <WasteTopBanner />
        </div>
      </div>
    );
  }
}

export default HomePageTopBanner;

import React, { Component } from "react";
import WasteTopBanner from "./wastetopbanner";

class AboutUsTopBanner extends Component {
  render() {
    return (
      <div className="topBannerContainer">
        <div
          className="aboutusbanner firstSegment"
          aria-label="SUEZ About Us"
          img
          src="/aboutus.jpg"
          alt="SUEZ About Us"
        >
          <WasteTopBanner />
        </div>
      </div>
    );
  }
}

export default AboutUsTopBanner;

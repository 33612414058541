import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import CookiePolicypdf from "../../documents/SUEZ Cookie Policy.pdf";
import PrivacyPolicypdf from "../../documents/SUEZ Privacy Policy.pdf";
import TermsConditionspdf from "../../documents/SUEZ General Terms Of Use.pdf";

class Footer extends Component {
  constructor() {
    const originUrl = window.location.origin;

    super();
    this.state = {
      origiUrl: window.location.origin,
      //Set value for deeplink
      baseUrl: originUrl + "/link/gdpr?GDPRDocument=",
      attribute: "",
    };
  }

  getTermsAndConditions = () => {
    window.open(TermsConditionspdf);
  };

  getcookiePolicy = () => {
    window.open(CookiePolicypdf);
  };
  getprivacyTerms = () => {
    window.open(PrivacyPolicypdf);
  };

  render() {
    return (
      <footer className="footer">
        <div className="row">
          <div className="col leftSectionFooter">
            <div className="linksWrapper">
              <h4 className="quicklinks">Quick links</h4>
              <div className="subquicklinkswrapper">
                <li className="subquicklinks boldfont">
                  <a
                    href="https://www.suez.co.uk/"
                    target="_blank"
                    className="aLink"
                    activeclassname="is-active"
                  >
                    Visit www.suez.co.uk
                  </a>
                </li>

                <li
                  className="subquicklinks gtm-siteterms boldfont"
                  value="TERMSANDCONDITIONS"
                  onClick={this.getTermsAndConditions}
                >
                  General terms of use
                </li>
                <li
                  className="subquicklinks gtm-cookiepolicy boldfont"
                  value="COOKIEPOLICY"
                  onClick={this.getcookiePolicy}
                >
                  Cookie policy
                </li>
                <li
                  className="subquicklinks gtm-privacyterms boldfont"
                  value="PRIVACYTERMS"
                  onClick={this.getprivacyTerms}
                >
                  Privacy policy
                </li>
                <li className="subquicklinks boldfont">
                  <NavLink
                    to="/contact-us"
                    className="aLink"
                    activeclassname="is-active"
                    onClick={window.scrollTo(0, 0)}
                  >
                    {" "}
                    Contact{" "}
                  </NavLink>
                </li>
              </div>

              <div className="socialMediaLink">
                <a
                  href="https://www.linkedin.com/company/suez-uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <div className="linkedin" aria-label="LinkedIn" />
                </a>

                <a
                  href="https://twitter.com/suezUK"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <div className="twitter" aria-label="Twitter" />
                </a>

                <a
                  href="https://www.youtube.com/c/SUEZUK"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  <div className="youtube" aria-label="Youtube" />
                </a>

                <a
                  href="https://www.facebook.com/suezukofficial"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Facebook"
                >
                  {" "}
                  <div className="facebook" />
                </a>
              </div>

              <div className="footerLogo" />
            </div>
            {/* copyrights text used to be here */}
          </div>

          {/* <div className="col footerMargins">
            <div className="footer-rightSection">
              
              <div className="footerLogo"></div>
              <div className="w-100"></div>
              <div className="col-md-12 socialMediaLink">
              <a href="https://www.linkedin.com/company/suez-uk" target="_blank" rel="noopener noreferrer"> <div className="linkedin" aria-label="LinkedIn"></div></a>

              <a href="https://twitter.com/suezUK" target="_blank" rel="noopener noreferrer"> <div className="twitter" aria-label="Twitter" ></div></a>
                
                <a href="https://www.youtube.com/c/SUEZUK" target="_blank" rel="noopener noreferrer"> <div className="youtube" aria-label="Youtube"></div></a>

                <a href="https://www.facebook.com/suezukofficial" target="_blank" rel="noopener noreferrer" aria-label="Facebook">  <div className="facebook" ></div></a>
              </div>
            </div>
          </div> */}
        </div>
        <div className="center-text addmargin">
          <span className="copy-rights ">
            ©  <div>{new Date().getFullYear()}&nbsp;</div> SUEZ Recycling and Recovery UK Ltd. SUEZ House, Grenfell Road,
            Maidenhead SL6 1ES. Registered company number: 02291198
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;

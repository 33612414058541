import React, { Component } from "react";
import GlassWasteTopBanner from "../topbannerComponent/glasswastetopbanner";
import WhyChooseUs from "../bodyComponent/whychooseus";
import { Helmet } from "react-helmet";
import Cookies from 'js-cookie';

class GlassRecycling extends Component {
  componentDidMount() {
    var data = {};
    data.event = "VirtualPageview";
    data.virtualPageURL = "/landing/glass";
    data.virtualPageTitle = "Landing Page (Glass)";
    // Enables the data layer if user has consented to enabling GTM, otherwise does not run it.
    // Data layer is used to enable GTM
    Cookies.get('statisticsCookiesConsent') == 'true' ? window.dataLayer.push(data) : console.log("")
  }

  render() {
    return (
      <div className="">
        <GlassWasteTopBanner />
        <Helmet>
          <title>
          Glass Recycling │ SUEZ UK Commercial Recycling and Waste Collection
          </title>
          <meta
            name="description"
            content="Commercial glass recycling collection services for businesses. Competitive pricing and environmentally friendly. Apply for an instant glass recycling collection quote online today."
          />
          <link rel="canonical" href="https://quotes.suez.co.uk/glass-recycling"/>
          <meta
            name="keywords"
            content="glass recycling, business glass recycling, glass recycling collectors, business glass recycling, glass recycling collection, glass recycling bin, glass recycling quote, glass recycling company, glass recycling quote, suez glass recycling, suez recycling and recovery, Commercial glass recycling"
          />
        </Helmet>

        <div className="MainBodyTextContainer">
          <h1 className="center-text">Glass recycling</h1>
          <div className="aligntextleft">
            <div className="">
              <div className="row">
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
                <div className="col-md-10 col-md-10 col-xs-10 col-sm-10">
                <p className="center-text boldfont">
                    Glass is 100% recyclable, yet so much of it is thrown away
                    and sent to landfill where it will never decompose.
                  </p>
                  <p>
                    SUEZ recycling and recovery UK provides reliable glass
                    recycling services that improve your recycling and waste
                    management system and help you avoid the rising costs of
                    landfill tax, while reducing your business' impact on the
                    environment.
                  </p>
                  <p>
                    Our hassle-free glass recycling collection service lets your
                    business recycle mixed glass bottles and glass jars. We
                    accept both clear and mixed glass, so there is no need to
                    sort and separate your coloured glass, leaving you the time
                    to focus on running your business.
                  </p>
                  <p>
                    Once we have collected your glass, it is sorted by colour
                    and washed. It is then crushed and melted before being
                    moulded into new products.
                  </p>
                </div>
                <div className="col-md-1 col-md-1 col-xs-1 col-sm-1"> </div>
              </div>
            </div>
          </div>
        </div>
        

        <div className="WhyUsContainer">
          <h2 className="center-text">
            Why choose our glass recycling service?
          </h2>
          <div className="ContainerBulletPoints">

          <div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">A cost-effective recycling service</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">
                  Regular, reliable and hassle-free collections
                </span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Excellent customer service</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">
                  Guaranteed compliance with regulations
                </span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">One invoice for all your services</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Range of user-friendly containers</span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">A free, no-obligation quote</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Free advice on how to recycle more</span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
          </div>
        </div>
        </div>


        <div className="WhatsPermittedContainer">
          <h2 className="center-text">What is allowed?</h2>
          <div className="ContainerBulletPoints">

          <div>
            <div className="row">
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Glass bottles</span>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                <span className="item">Glass jars</span>
              </div>
              <div className="col-lg-0 col-md-0 col-sm-0 col-xs-0" />
            </div>
          </div>
        </div>
        </div>

        <div className="ContainerSizesContainer">
          <h2 className="center-text">Container sizes</h2>

          <div className="col-xs-4 col-sm-9 col-md-6 col-lg-4 OuterContainerSizesContainer">
            <div className="row">
              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-glassrecycling-140L img-fluid"
                  alt="140L"
                >
                  {" "}
                </div>
                <h5 className="card-title">140L</h5>
              </div>

              <div className="InnerCenterContainerSizesContainer">
                <div
                  className="card-img-glassrecycling-240L img-fluid"
                  alt="240L"
                >
                  {" "}
                </div>
                <h5 className="card-title">240L</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default GlassRecycling;
